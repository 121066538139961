<template>
  <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }">
    <a-form-item label="门店名称">
      <a-input v-model="form.s_name" />
    </a-form-item>
    <a-form-item label="门店照片">
      <a-upload
        name="file"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        action="http://api2.jcwh.xyz/common/Uploads/shop"
        :before-upload="imageUpload"
        @change="imageChange"
      >
        <img
          v-if="form.s_logo"
          style="width: 420px"
          :src="form.s_logo"
          alt="avatar"
        />
        <div v-else>
          <a-icon :type="imageLoading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
      (支持格式：jpg,png 大小：2M以下 建议尺寸：750px * 750px)
      
    </a-form-item>
    <a-form-item label="商家联系方式">
      <span>{{form.s_phone}}</span>
    </a-form-item>
    <a-form-item label="门店地址">
      <a-input v-model="form.s_address" />
    </a-form-item>
    <a-form-item label="经营范围">
      <a-input disabled v-model="form.s_range" />
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
      <a-button type="primary" :loading="subLoading" @click="submit">
        提交
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import qs from "qs";
import { storeInfo, storeUpdateInfo } from "@/assets/api/api";

export default {
  data() {
    return {
      imageLoading: false,
      form: {
        s_name: "",
        s_logo: "",
        s_tel: "",
        s_address: "",
        s_range:"",
      },
      subLoading: false,
    };
  },
  methods: {
    imageChange(info) {
      this.imageLoading = true;
      if (info.file.status === "done") {
        this.form.s_logo = info.file.response.data;
        this.imageLoading = false;
      }
    },
    imageUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error(
          "上传图片大小不能超过2MB!"
        );
      }
      return isJpgOrPng && isLt2M;
    },
    storeInfo() {
      storeInfo().then((res) => {
        if (res.data.code == 0) {
          this.form = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    submit() {
      if (this.form.s_name == "") {
        this.$message.error("请输入门店名称");
      } else if (this.form.s_logo == "") {
        this.$message.error("请上传门店照片");
      } else if (this.form.s_tel == "") {
        this.$message.error("请输入商家联系方式");
      } else if (this.form.s_address == "") {
        this.$message.error("请输入门店地址");
      }  else {
        this.subLoading = true;
        storeUpdateInfo(qs.stringify(this.form)).then((res) => {
          this.subLoading = false;
          if (res.data.code == 0) {
            this.$message.success("提交成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
  },
  mounted() {
    this.storeInfo();
  },
};
</script>